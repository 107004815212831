<template>
  <v-container fluid class="mr-10">
    <div v-if="hasAuthorities()" class="mx-10">
      <TitleRow title="SDI - Movimentação" />
      <MovementSection @verifyAuthorityMovements="handleVerifyAuthorityMovements" />

      <SeparatorGradient v-if="authorityMovements" />

      <FileConfigurationSection @verifyAuthorityFileConfigurations="handleVerifyAuthorityFileConfigurations" />

      <SeparatorGradient v-if="authorityFileConfigurations" />

      <GeneralParameterSection @verifyAuthorityGeneralParameters="handleVerifyAuthorityGeneralParameters" />
    </div>

    <v-row v-else align="center" justify="center" class="mt-15">
      <v-col align="center" cols="12">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-low-vision</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import MovementSection from '@/components/Home/AllMenus/MovementSection.vue';
import FileConfigurationSection from '@/components/Home/AllMenus/FileConfigurationSection.vue';
import GeneralParameterSection from '@/components/Home/AllMenus/GeneralParameterSection.vue';
import SeparatorGradient from '@/components/Home/AllMenus/SeparatorGradient.vue';
import TitleRow from '@/components/Home/AllMenus/TitleRow.vue';

export default ({
  components: {
    MovementSection, FileConfigurationSection, GeneralParameterSection, SeparatorGradient, TitleRow,
  },
  data: () => ({
    isRHProtegido: false,
    authorityMovements: true,
    authorityFileConfigurations: true,
    authorityGeneralParameters: true,
  }),

  async mounted() {
    this.clearSessionStorage();
    if (this.isRHProtegido) this.redirectRouter('HomeRHProtegido', { isRHProtegido: this.isRHProtegido });
  },

  mixins: [
    VerifyRoutesMixin,
  ],

  methods: {
    clearSessionStorage() {
      const keyToken = '@auth/token';
      const valueToken = sessionStorage.getItem(keyToken);
      const keyUser = '@auth/user';
      const keyAppOrigin = '@sdi/app_origin';
      const valueUser = sessionStorage.getItem(keyUser);

      sessionStorage.clear();
      sessionStorage.setItem(keyToken, valueToken);
      sessionStorage.setItem(keyUser, valueUser);
      sessionStorage.removeItem('financialGroupId');
      if(this.isRHProtegido){
        sessionStorage.setItem(keyAppOrigin, 'PROTECTED_HR');
      }else {
        sessionStorage.setItem(keyAppOrigin, 'BACKOFFICE');
      }
    },
    handleVerifyAuthorityMovements(hasAuthorityToAccess) {
      this.authorityMovements = hasAuthorityToAccess;
    },
    handleVerifyAuthorityFileConfigurations(hasAuthorityToAccess) {
      this.authorityFileConfigurations = hasAuthorityToAccess;
    },
    handleVerifyAuthorityGeneralParameters(hasAuthorityToAccess) {
      this.authorityGeneralParameters = hasAuthorityToAccess;
    },
    hasAuthorities() {
      if (this.authorityMovements || this.authorityFileConfigurations || this.authorityGeneralParameters) {
        return true;
      }
      return false;
    },
  },
});
</script>
